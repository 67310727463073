import React from "react";
import classes from "./Logo.module.css";
import { Link } from "react-router-dom";

let logo =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Flogo%2Fvintage-logo.png?alt=media&token=3b6ce3e7-5078-45bf-af04-2ea945b072d6";

const Logo = () => {
  return (
    <Link to="/" className={classes.Logo}>
      <img src={logo} alt="vintage-logo" className={classes.LogoImg} />
    </Link>
  );
};

export default Logo;
