import React from "react";
import classes from "./PrivilegeLoan.module.css";

let paperLess =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-3%2Fbest%2Fpaper-less.png?alt=media&token=f02e9c31-efe1-4e87-91f9-a1bc62a77e72";

let disbursal =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-3%2Fbest%2FGroup%2015206.svg?alt=media&token=84de5fac-1dc0-42bf-bd5c-cc3a9c37ed87";

let flexible =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-3%2Fbest%2FGroup%2015205.svg?alt=media&token=a44b2565-02a4-4c83-979a-465210e73337";

let history =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-3%2Fbest%2FGroup%2015554.svg?alt=media&token=75538ee1-b589-45ec-b2c5-5f0966a751da";

let lowInterest =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-3%2Fbest%2Flow-interest.png?alt=media&token=837ec8ab-c9cf-40a1-8f5e-978f22fe1a65";

let arrow =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-3%2Fcard%2Farrow.png?alt=media&token=738d6358-9abf-4d0f-b86e-ac518bce7443";
const PrivilegeLoan = ({ open }) => {
  return (
    <div className={classes.Privilege}>
      <div>
        <h3 className={classes.H3}>
          The Vintage Finance <br /> Privileges
        </h3>
      </div>
      <div className={classes.Row}>
        <div className={classes.Column}>
          <div className={classes.Card}>
            <div className={classes.Upper}>
              <h4 className={classes.H4}>Why are we the best?</h4>
              <h5 className={classes.H5}>
                With an aim to provide hassle free instant loans to borrowers
                our loan products can be availed within 24 hours without
                physical verification and with minimal documentation.
              </h5>
            </div>
            <div className={classes.BtnDiv}>
              <button className={classes.Btn} onClick={open}>
                Apply Now
                <img
                  src={arrow}
                  alt="dir"
                  style={{
                    marginLeft: "10px",
                    width: "23.15px",
                    height: "11.44px",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className={classes.SmallColumn}>
          <div>
            <img src={paperLess} alt="loan" className={classes.BestImg} />
            <h6 className={classes.H6}>
              100% PAPERLESS & <br /> ONLINE APPROVAL
            </h6>
            <p className={classes.P}>
              No loads of paperwork. Just <br /> apply, upload and get approved.
            </p>
          </div>
          <div>
            <img src={history} alt="loan" className={classes.BestImg} />
            <h6 className={classes.H6}>
              No Credit History <br /> Required
            </h6>
            <p className={classes.P}>
              Even if you don’t have any credit history, you can make one by
              taking a.
            </p>
          </div>
          <div>
            <img src={disbursal} alt="loan" className={classes.BestImg} />
            <h6 className={classes.H6}>QUICK DISBURSAL</h6>
            <p className={classes.P}>
              Get the funds in your account <br /> within 2 days of approval
            </p>
          </div>
          <div>
            <img src={lowInterest} alt="loan" className={classes.BestImg} />
            <h6 className={classes.H6}>Low Interest rate</h6>
            <p className={classes.P}>
              Flexible and attractive <br /> interest rates
            </p>
          </div>
          <div>
            <img src={flexible} alt="loan" className={classes.BestImg} />
            <h6 className={classes.H6}>Personalized and Flexible</h6>
            <p className={classes.P}>
              Spend money in your own way with <br className={classes.Mb} />{" "}
              flexible personal loan amounts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivilegeLoan;
