import React from "react";
import { useHistory } from "react-router-dom";
import { dsaSignup } from "../../App";
import classes from "./ApplyLoanCard.module.css";
//import "lazysizes";

let handshake =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-7%2Fapply-now%2Fhandshake.webp?alt=media&token=3665e609-a9ab-4f96-a377-22cc3199d4f9";

let home =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-7%2Fapply-now%2Fhouse1.webp?alt=media&token=08806357-03bf-4c8d-be9c-8167218e564c";

const ApplyLoanCard = ({ open }) => {
  const history = useHistory();
  return (
    <div className={classes.ApplyCard}>
      <div className={classes.Card}>
        <img
          data-src={home}
          alt="home-loan"
          className={classes.Img + " lazyload"}
          style={{ padding: "20px 48px" }}
        />
        <div className={classes.Text}>
          <h3 className={classes.H3}>
            Apply now & get loan upto ₹15 lakhs without CIBIL.
          </h3>
          <h4 className={classes.H6}>
            Apply loan and make your dreams come true on easy and affordable EMI
          </h4>
        </div>
        <div className={classes.BtnDiv}>
          <button className={classes.BtnG} onClick={open}>
            GET INSTANT APPROVAL
          </button>
        </div>
      </div>
      <div className={classes.Card}>
        <img
          data-src={handshake}
          alt="handshake"
          className={classes.Img + " lazyload"}
          style={{ width: "100%" }}
        />
        <div className={classes.Text}>
          <h3 className={classes.H3}>
            Our Channel Partners are earning ₹150000 p/m
          </h3>
          <h4 className={classes.H6}>
            To know more about the DSA program, click now,
          </h4>
        </div>
        <div className={classes.BtnDiv} onClick={() => history.push(dsaSignup)}>
          <button className={classes.BtnD}>JOIN OUR DSA PROGRAM</button>
        </div>
      </div>
    </div>
  );
};

export default ApplyLoanCard;
