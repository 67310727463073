import React from "react";
//import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
//import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from "@material-ui/core/DialogContentText";
//import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import styles from "./Dialog.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ dialog, setDialog }) {
  return (
    <div style={{ position: "relative" }}>
      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDialog(false)}
        className={styles.DialogMain}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <div className={styles.DialogRow}>
            <div onClick={() => window.open("/home-loan", "_self")}>
              <img
                alt="home-loan"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015437.png?alt=media&token=881693f3-3cdc-4f08-bc41-b38dc1c6fe69"
              />
              <h5>Home Loan</h5>
            </div>
            <div
              onClick={() =>
                window.open(
                  "/personal-loan-cibil-check-providers-delhi",
                  "_self"
                )
              }
            >
              <img
                alt="personal-loan"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015178.png?alt=media&token=8b888bac-bd0d-42bf-9b93-22ac46d568c7"
              />
              <h5>Personal Loan</h5>
            </div>
            <div
              onClick={() =>
                window.open("/personal-loan-low-cibil-defaulter-delhi", "_self")
              }
              className={styles.Mb}
            >
              <img
                alt="cibil"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015178.png?alt=media&token=8b888bac-bd0d-42bf-9b93-22ac46d568c7"
              />
              <h5>
                Loan for Cibil
                <br /> Defaulter
              </h5>
            </div>
          </div>
          <div className={styles.DialogRow}>
            <div onClick={() => window.open("/early-salary-loan", "_self")}>
              <img
                alt="early-salary-loan"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015463.png?alt=media&token=980fb47b-555e-42b7-8c58-a080affc45f7"
              />
              <h5>
                Early Salary
                <br /> Loan
              </h5>
            </div>
            <div onClick={() => window.open("/two-wheeler-loan", "_self")}>
              <img
                alt="two-wheeler-loan"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015177.png?alt=media&token=ef9e95e4-b51d-4149-b4d0-c41f897e43a6"
              />
              <h5>
                Two wheeler
                <br /> Loan
              </h5>
            </div>
          </div>
          <div className={styles.DialogRow}>
            <div
              onClick={() =>
                window.open("/loan-against-property-delhi", "_self")
              }
            >
              <img
                alt="loan-property"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015178.png?alt=media&token=8b888bac-bd0d-42bf-9b93-22ac46d568c7"
              />
              <h5>
                Loan Against
                <br /> Property
              </h5>
            </div>
            <div
              onClick={() => window.open("/personal-loan-noida", "_self")}
              className={styles.Mb}
            >
              <img
                alt="loan-in-noida"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015178.png?alt=media&token=8b888bac-bd0d-42bf-9b93-22ac46d568c7"
              />
              <h5>
                Loan in
                <br /> Noida
              </h5>
            </div>
          </div>
          <div className={styles.DialogRow}>
            <div
              onClick={() => window.open("/personal-loan-gurgaon", "_self")}
              className={styles.Mb}
            >
              <img
                alt="loan-in-gurgaon"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015178.png?alt=media&token=8b888bac-bd0d-42bf-9b93-22ac46d568c7"
              />
              <h5>
                Loan in
                <br /> Gurgaon
              </h5>
            </div>
            <div
              onClick={() => window.open("/personal-loan-ghaziabad", "_self")}
              className={styles.Mb}
            >
              <img
                alt="loan-in-ghaziabad"
                src="https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Floan-products%2FGroup%2015178.png?alt=media&token=8b888bac-bd0d-42bf-9b93-22ac46d568c7"
              />
              <h5>
                Loan in
                <br /> Ghaziabad
              </h5>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
