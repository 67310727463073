import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  earlySalaryLoan,
  loanAgainstProperty,
  personalLoan,
  twoWheelerLoan,
} from "../../App";
import EmiCalculator from "../EmiCalculator/EmiCalculator";
import classes from "./PersonalLoan.module.css";

let personalLoanCard =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2FnewCards%2FGroup%2015217.png?alt=media&token=ab449d60-7652-4ed1-997c-dd1b6b84828d";

let earlySalaryCard =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2FnewCards%2FGroup%2015071.png?alt=media&token=75b6e792-2766-4aaa-99a4-e3c04104235c";

// let homeLoanCard =
//   "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2FnewCards%2FGroup%2015075.png?alt=media&token=b6e18e7b-ad89-4d56-8cce-bb22ef994e9b";

let propertyLoanCard =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2FnewCards%2FGroup%2015219.png?alt=media&token=9b57acb5-0c04-4429-8362-46dbbf840612";

let twoWheelerCard =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Fcards%2FGroup-15552.webp?alt=media&token=773c7f85-1218-4b49-a5d6-ee0e33a4d607";

let marriageLoan =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Fmarriage-loan.png?alt=media&token=11588cb6-838c-463a-9714-6a75197199d1";
// let carLoan =
//   "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Fcar-loan.png?alt=media&token=f88b8de3-ec37-49e0-a9ed-611de7804e88";

let debtLoan =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Fdebt-loan.png?alt=media&token=80d569e0-489a-4298-87ce-540f079e2187";

// let educationLoan =
//   "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Feducation-loan.png?alt=media&token=eabf1c49-b359-41df-8a66-3a4ca8056ff4";

// let homeLoanImage =
//   "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Fhome-loan.png?alt=media&token=92d55960-21ba-490f-8f79-47db71cdb451";

// let laptopLoan =
//   "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Flaptop-loan.png?alt=media&token=403f62a1-1a86-47d2-8738-be97cdf6f689";

let medicalLoan =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Fmedical-loan.png?alt=media&token=87808842-483b-4d26-b2ab-2e579aceb71c";

let mobileLoan =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Fmobile-loan.png?alt=media&token=48bacb8d-9c26-464a-b272-a82d937072a9";

let twoWheelerLoanImage =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Fscooter-loan.png?alt=media&token=f62ad731-53da-467c-b23b-dd86420d195e";

// let travelLoan =
//   "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Floan-type%2Ftravel-loan.png?alt=media&token=6c0b9ceb-2331-4209-b364-3a44aca70872";

let personalLoanMb =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Fmobile-images%2FGroup%2015226.png?alt=media&token=ad1c2cfc-4d37-425a-a10e-dabeea8c84b8";

let twoWheelerLoanMb =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Fmobile-images%2FGroup-15553.webp?alt=media&token=d593c6a3-e54d-4da6-b42a-5424c93e0768";

let propertyLoanMb =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Fmobile-images%2Flp.png?alt=media&token=96120e0c-1edd-4454-b27a-93842c8b141c";

// let homeLoanMb =
//   "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Fmobile-images%2Fhm-ln.png?alt=media&token=31026314-563f-470c-89e7-0d1268d368fd";

let earlyLoanMb =
  "https://firebasestorage.googleapis.com/v0/b/vintage-fin.appspot.com/o/images%2Fsection-2%2Fmobile-images%2Fel.png?alt=media&token=b342f650-a571-4549-8887-9f526f6a21d4";

const PersonalLoan = ({ open, close }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <div className={classes.PersonalLoan} id={"loan"}>
      <div>
        <h2 className={classes.H2}>
          Get a <br className={classes.BrWe} /> pre-approved personal
          <br className={classes.BrMb} /> loan upto ₹15 lakhs
          <br className={classes.BrWe} /> in just <br /> 5 minutes.
        </h2>
        <p className={classes.P}>
          Vintage Finance offers instant short-term loans in order to make your
          life simple.
          <br className={classes.BrMb} /> We have innovative solutions to limit
          you from facing a money crunch. We <br className={classes.BrMb} />{" "}
          ensure that our customers have sufficient funds to shop, socialize,
          travel <br className={classes.BrMb} /> and pay their bills on time
          with their instant loans.
        </p>
      </div>
      <div className={classes.LoanProducts}>
        <div>
          <h3 className={classes.H5}>Explore our loan products</h3>
          <h4 className={classes.H6}>
            You can get a loan against property, personal loans for domestic or
            international <br className={classes.BrMb} /> vacation, wedding, to
            buy a latest gadget, higher education, purchase of vehicle
            <br className={classes.BrMb} /> or even home renovation. Vintage
            Finance personal loans will help you fulfil your{" "}
            <br className={classes.BrMb} /> dreams at in 3 quick steps.
          </h4>
        </div>
        <div>
          <button className={classes.Btn}>Explore other loan products</button>
          <button
            className={classes.Btn}
            style={{ background: "#B9F59D", marginTop: "31px" }}
            onClick={open}
          >
            Apply now. Instant loan approval
          </button>
        </div>
      </div>
      <div className={classes.LoanCards}>
        <img
          src={personalLoanCard}
          alt="personal-loan"
          className={classes.LoanCard}
          onClick={() => history.push(personalLoan)}
        />
        {/* <img
          src={homeLoanCard}
          alt="home-loan"
          className={classes.LoanCard}
          onClick={() => history.push(homeLoan)}
        /> */}

        <img
          src={twoWheelerCard}
          alt="two-wheeler-loan"
          className={classes.LoanCard}
          onClick={() => history.push(twoWheelerLoan)}
        />
        <img
          src={earlySalaryCard}
          alt="early-salary-loan"
          className={classes.LoanCard}
          onClick={() => history.push(earlySalaryLoan)}
        />
        <img
          src={propertyLoanCard}
          alt="property-loan"
          className={classes.LoanCard}
          onClick={() => history.push(loanAgainstProperty)}
        />
      </div>

      <div className={classes.LoanCardsMb}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            marginBottom: "60px",
            marginTop: "60px",
          }}
          onClick={() => history.push(personalLoan)}
        >
          <img src={personalLoanMb} alt="loan" />
          <button className={classes.BtnMb}>Get Instant Loan</button>
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            marginBottom: "60px",
            marginTop: "60px",
          }}
          onClick={() => history.push(homeLoan)}
        >
          <img src={homeLoanMb} alt="loan" />
          <button className={classes.BtnMb}>Get Instant Loan</button>
        </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            marginBottom: "60px",
            marginTop: "60px",
          }}
          onClick={() => history.push(loanAgainstProperty)}
        >
          <img src={propertyLoanMb} alt="loan" />
          <button className={classes.BtnMb}>Get Instant Loan</button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            marginBottom: "60px",
          }}
          onClick={() => history.push(twoWheelerLoan)}
        >
          <img src={twoWheelerLoanMb} alt="loan" />
          <button className={classes.BtnMb}>Get Instant Loan</button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            marginBottom: "60px",
          }}
          onClick={() => history.push(earlySalaryLoan)}
        >
          <img src={earlyLoanMb} alt="loan" />
          <button className={classes.BtnMb}>Get Instant Loan</button>
        </div>
      </div>
      <div className={classes.grid}>
        <div>
          <h4 className={classes.H4}>
            In the time of need, <br className={classes.BrMb} /> you can count
            on us !
          </h4>
          <h5 className={classes.H6}>
            Vintage Finance personal loan serves multiple purposes and is
            available for <br className={classes.BrMb} /> all salaried
            individuals. Life can throw up a surprise any moment in the form{" "}
            <br className={classes.BrMb} /> of opportunities or challenges.
            Thus, from financing a wedding, travel, child’s{" "}
            <br className={classes.BrMb} /> education, vacation, medical
            emergency, any big-ticket purchase, home{" "}
            <br className={classes.BrMb} /> repair or even debt consolidation –
            we are here for you.
          </h5>
        </div>
        {pathname === "/services.html" && <EmiCalculator />}
      </div>
      <div className={classes.LoanType}>
        <div>
          <img
            src={marriageLoan}
            alt="marriage-loan"
            className={classes.LoanTypeImg}
          />
          <h3 className={classes.H3}>Marriage Loan</h3>
        </div>
        {/* <div>
          <img
            src={travelLoan}
            alt="travel-loan"
            className={classes.LoanTypeImg}
          />
          <h3 className={classes.H3}>Travel Loan</h3>
        </div> */}
        <div>
          <img
            src={medicalLoan}
            alt="medical-loan"
            className={classes.LoanTypeImg}
          />
          <h3 className={classes.H3}>Medical Loan</h3>
        </div>
        {/* <div>
          <img
            src={educationLoan}
            alt="education-loan"
            className={classes.LoanTypeImg}
          />
          <h3 className={classes.H3}>Education Loan</h3>
        </div> */}
        {/* <div>
          <img
            src={homeLoanImage}
            alt="home-loan"
            className={classes.LoanTypeImg}
          />
          <h3 className={classes.H3}>Home Loan</h3>
        </div> */}
        {/* <div>
          <img src={carLoan} alt="car-loan" className={classes.LoanTypeImg} />
          <h3 className={classes.H3}>Used car Loan</h3>
        </div> */}
        {/* <div>
          <img
            src={laptopLoan}
            alt="laptop-loan"
            className={classes.LoanTypeImg}
          />
          <h3 className={classes.H3}>Laptop Loan</h3>
        </div> */}
        <div>
          <img
            src={mobileLoan}
            alt="mobile-loan"
            className={classes.LoanTypeImg}
          />
          <h3 className={classes.H3}>Mobile Loan</h3>
        </div>
        <div>
          <img
            src={twoWheelerLoanImage}
            alt="two-wheeler-loan"
            className={classes.LoanTypeImg}
          />
          <h3 className={classes.H3}>Two-Wheeler Loan</h3>
        </div>
        <div>
          <img src={debtLoan} alt="debt-loan" className={classes.LoanTypeImg} />
          <h3 className={classes.H3}>
            Debt Consolidation
            <br /> Loan
          </h3>
        </div>
        <div className={classes.ExtraStyle}>
          <img src={debtLoan} alt="debt-loan" className={classes.LoanTypeImg} />
          <h3 className={classes.H3}>
            Debt Consolidation
            <br /> Loan
          </h3>
        </div>
      </div>
      {pathname === "/services.html" && (
        <div className={classes.bottom}>
          <h6 className={classes.disclaimer}>
            <span>Disclaimer</span> Please note that the calculator has been
            created to give you an approximate understanding and should not be
            considered as absolute.Based on your Loan tenure and interest rates,
            the calculator will also help you to best understand the ratio of
            the principal amount to the interest due. The amount of interest in
            your EMI will be greater in the initial months and will eventually
            reduce with each and every EMI paid. Thus, whilst your EMI amount
            remains the same, the ratio of principal to interest in your EMI
            will change over a period. With each successive payment, you will
            pay more towards the principal and less in interest.
          </h6>
        </div>
      )}
    </div>
  );
};

export default PersonalLoan;
