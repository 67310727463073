import React from "react";
import Helmet from "react-helmet";
import CibilScore from "../../components/CibilScore/CibilScore";

import AboutUs from "../../components/AboutUs/AboutUs";
import ApplyLoanCard from "../../components/ApplyLoanCard/ApplyLoanCard";
import Faq from "../../components/Faq/Faq";
import Numbers from "../../components/Numbers/Numbers";
import PersonalLoan from "../../components/PersonalLoan/PersonalLoan";
//import PressRelease from "../../components/PressRelease/PressRelease";
import PrivilegeLoan from "../../components/PrivilegeLoan/PrivilegeLoan";
import Rating from "../../components/Rating/Rating";
import StepsForInstantLoan from "../../components/StepsForInstantLoan/StepsForInstantLoan";

// const AboutUs = lazy(() => import("../../components/AboutUs/AboutUs"));
// const ApplyLoanCard = lazy(() =>
//   import("../../components/ApplyLoanCard/ApplyLoanCard")
// );
// const Faq = lazy(() => import("../../components/Faq/Faq"));
// const Numbers = lazy(() => import("../../components/Numbers/Numbers"));
// const PersonalLoan = lazy(() =>
//   import("../../components/PersonalLoan/PersonalLoan")
// );
// // const PressRelease = lazy(() =>
// //   import("../../components/PressRelease/PressRelease")
// // );
// const PrivilegeLoan = lazy(() =>
//   import("../../components/PrivilegeLoan/PrivilegeLoan")
// );
// const Rating = lazy(() => import("../../components/Rating/Rating"));
// const StepsForInstantLoan = lazy(() =>
//   import("../../components/StepsForInstantLoan/StepsForInstantLoan")
// );

const Home = ({ mobile, open, setToast }) => {
  //let fallback = "Loading...";
  return (
    <>
      <Helmet>
        <title>
          Get Instant Approval Loan in Delhi | Loan Provider in New
          Delhi,Gurgaon ,Noida | Online Apply for Personal Loan | Payday Loan
          delhi
        </title>
        <meta
          name="description"
          content="New Delhi Loan Provider:  Get Instant Approval Loan in New Delhi NCR. Online Apply for Personal Loan and payday loan./"
        />
        <meta
          name="keywords"
          content="Get Instant Approval Loan in Delhi, Loan Provider in New Delhi NCR, new delhi loan provider, personal loan in delhi,Online Apply for Personal Loan. Loan Service Providers in Delhi, Quick loans in Delhi, Cash loans in Delhi. New Delhi personal loan, nbfc in Delhi, Low Cibil Score Personal Loan Delhi."
        />
      </Helmet>
      <div>
        <CibilScore setToast={setToast} />

        <PersonalLoan open={open} />
        <PrivilegeLoan open={open} />
        <StepsForInstantLoan open={open} />
        <Numbers open={open} />
        <Rating mobile={mobile} open={open} />
        <AboutUs open={open} />
        {/* <PressRelease /> */}
        <ApplyLoanCard open={open} />
        <Faq mt={{ marginTop: 60 }} />
      </div>
    </>
  );
};

export default Home;
